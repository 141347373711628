// Generated by Framer (1f5f6d9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {vnyDcZAfz: {hover: true}};

const serializationHash = "framer-B4uHX"

const variantClassNames = {vnyDcZAfz: "framer-v-2qlct9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, mouseEnter, width, ...props}) => { return {...props, jgyrwfHDu: mouseEnter ?? props.jgyrwfHDu} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, jgyrwfHDu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "vnyDcZAfz", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1f3p2iz = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (jgyrwfHDu) {const res = await jgyrwfHDu(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-2qlct9", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"vnyDcZAfz"} onMouseEnter={onMouseEnter1f3p2iz} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4f9bfb1d-5962-4b86-b608-6328c5a03a7f, rgb(0, 103, 255))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-f291c9e3-d009-4a64-9a24-ec7b7592bf11, rgb(235, 247, 255))", borderBottomLeftRadius: 9, borderBottomRightRadius: 9, borderTopLeftRadius: 9, borderTopRightRadius: 9, ...style}} variants={{"vnyDcZAfz-hover": {backgroundColor: "var(--token-f755b6d6-424c-4602-8c17-98abc068768e, rgb(209, 238, 255))"}}} {...addPropertyOverrides({"vnyDcZAfz-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128)))"}}>Cómo funciona</motion.p></React.Fragment>} className={"framer-1asozk9"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"KcUHRdVbC"} style={{"--extracted-r6o4lv": "var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-B4uHX.framer-umlco0, .framer-B4uHX .framer-umlco0 { display: block; }", ".framer-B4uHX.framer-2qlct9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 54px; justify-content: center; overflow: visible; padding: 0px 24px 0px 20px; position: relative; width: min-content; }", ".framer-B4uHX .framer-1asozk9 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-B4uHX.framer-2qlct9 { gap: 0px; } .framer-B4uHX.framer-2qlct9 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-B4uHX.framer-2qlct9 > :first-child { margin-left: 0px; } .framer-B4uHX.framer-2qlct9 > :last-child { margin-right: 0px; } }", ".framer-B4uHX[data-border=\"true\"]::after, .framer-B4uHX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54
 * @framerIntrinsicWidth 161
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"l7sizmPLy":{"layout":["auto","fixed"]}}}
 * @framerVariables {"jgyrwfHDu":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVdNDpFiZq: React.ComponentType<Props> = withCSS(Component, css, "framer-B4uHX") as typeof Component;
export default FramerVdNDpFiZq;

FramerVdNDpFiZq.displayName = "Secoundary";

FramerVdNDpFiZq.defaultProps = {height: 54, width: 161};

addPropertyControls(FramerVdNDpFiZq, {jgyrwfHDu: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerVdNDpFiZq, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})